<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>{{ $t('title.lottoSetting') }}</v-toolbar-title>

      <v-btn class="ml-5" color="primary" @click="onCreateDialog" v-if="checkUserPermission('CREATE')">{{ $t('button.create') }}</v-btn>

      <dialog-delete
          :dialog-delete="dialogDelete"
          :darkmode="darkmode"
          v-on:closeDelete="closeDelete"
          v-on:deleteItemConfirm="deleteItemConfirm"
      /> 
    </v-toolbar>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import DialogDelete from "../../../../components/shared/notify/DialogDelete";

export default {
  components: {DialogDelete},
  props: ["darkmode", "status", "params", "dataDelete", 'userPermission'],
  data() {
    return {
      dialogDelete: false,
      items:[
        { _id:1,
          name:"លុយដុល្លា - Usd"
        },
        { _id:2,
          name:"លុយរៀល - Khmer"
        },
        { _id:3,
          name:"លុយបាត - Baht"
        },
        ],
        type_id:1
    };
    
  },
  methods: {
    closeDelete() {
      this.dialogDelete = !this.dialogDelete
    },

    async deleteItemConfirm() {
      await this.deleteData({
        vm: this,
        data: {deleteItems: this.dataDelete}
      });
      this.fetchGetAllConfigUser(this.params)
      this.dialogDelete = false;
    },
    
      async selectLimitBetting(type_id) {
        this.params.currency_type = type_id
        this.fetchGetAllConfigUser(this.params)
    },

    async onCreateDialog() {
      this.fetchPopUp(true)
    },
    ...mapActions("$_configUser", ["fetchGetAllConfigUser", "deleteData", "fetchPopUp"]),
  },
};
</script>

<style lang="scss" scoped>
</style>
